import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { makeDateFromFileNameDate, makeDateArray, isDateValid } from "../helpers/dateUtils";

import { useCompanyStore } from "./company";
import { useSearchStore } from "./search";

import { handleGraphQLQuery } from "../helpers/graphqlUtils.js";

import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { sub } from "date-fns";

// Check for valid filenames (Example Sal_550_002-01_22023)
// https://regex101.com/r/Y23J5b/1 (only remove the g and m at the end)
function isFilenameInAutoFormat(filename) {
  const newRegex = /^(?<companycode>\d{1,6})_(?<date>\d{6})/i;

  //console.log("regex", regex.exec(filename));
  if (newRegex.test(filename)) {
    return newRegex.exec(filename).groups;
  }
  return false;
}

// Start a new salary control workflow
const { mutate: createSalaryWorkflow } = useMutation(
  gql`
    mutation startSalaryControlWorkflow($data: HrSalaryWorkflowCreateInput!) {
      createHrSalaryWorkflow(data: $data) {
        id
      }
    }
  `
);

// To update a salary control document (status, feedback from client)
const { mutate: updateSalaryControlDocument } = useMutation(
  gql`
    mutation UpdateHrSalaryWorkflowDocument(
      $where: HrSalaryWorkflowDocumentWhereUniqueInput!
      $data: HrSalaryWorkflowDocumentUpdateInput!
    ) {
      updateHrSalaryWorkflowDocument(where: $where, data: $data) {
        id
      }
    }
  `
);

// To add a new document to an existing workflow
const { mutate: addSalaryControlDocumentToWorkflow } = useMutation(
  gql`
    mutation CreateHrSalaryWorkflowDocument($data: HrSalaryWorkflowDocumentCreateInput!) {
      createHrSalaryWorkflowDocument(data: $data) {
        id
      }
    }
  `
);

export const useHrSalaryControlStore = defineStore("salaryControl", {
  state: () => ({
    documents: useStorage("salarycontrol", []),
    documentsTotal: 0,
    newDocuments: [],
    defaultDates: makeDateArray(),
    loading: false,
    take: 15,
    skip: 0,
  }),
  getters: {
    allNewDocuments: (state) => state.newDocuments || [],
    checkIfNewDocumentsAreComplete: (state) => {
      let complete = true;
      state.newDocuments.forEach((doc) => {
        const { feedbackFg, ...rest } = Object.assign({}, doc.data); // filter out feedbackFg
        Object.values(rest).some((value) => {
          if (!value) {
            complete = false;
          }
        });
      });
      //console.log("checkIfNewDocumentsAreComplete", complete);
      return complete;
    },
    canLoadMore: (state) => {
      if (state.documentsTotal > state.documents.length) {
        return true;
      }
      return false;
    },
    findNewDocumentById: (state) => (id) => {
      return state.newDocuments.find((doc) => doc.id === id);
    },
  },
  actions: {
    async getDocuments(init = false, clientMode = false) {
      if (this.loading) return;

      const searchStore = useSearchStore();
      let contextCompany = searchStore.getCompanyFilter;
      if (clientMode === false) {
        contextCompany = {};
      }

      // Client Mode only limits the time range to 28 days, reducing the amount of results.
      let createdAt = {};
      /*
      if (clientMode === true) {
        this.take = 1;
        //createdAt = sub(new Date(), { days: 28 });
      } else {
        createdAt = { createdAt: { gte: sub(new Date(), { months: 3 }).toISOString() } };
      }
      */

      if (init === true) {
        this.skip = 0;
      }

      this.loading = true;
      try {
        // use apollo client directly
        const result = await handleGraphQLQuery(
          gql`
            query SalaryWorkflow(
              $take: Int
              $skip: Int!
              $orderBy: [HrSalaryWorkflowOrderByInput!]!
              $where: HrSalaryWorkflowWhereInput!
            ) {
              hrSalaryWorkflows(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
                id
                status
                date
                company {
                  id
                  name
                  personInChargeHumanResources {
                    id
                    name
                  }
                }
                documents(orderBy: { createdAt: asc }) {
                  id
                  document {
                    filename
                    filesize
                  }
                  status
                  feedback
                  feedbackFg
                  createdAt
                  createdBy {
                    id
                    name
                    email
                    emailHash
                  }
                  reviewedAt
                  reviewedBy {
                    id
                    name
                    user {
                      emailHash
                    }
                  }
                }
                createdAt
                createdBy {
                  id
                  name
                  emailHash
                }
                updateAt
              }
              hrSalaryWorkflowsCount(where: $where)
            }
          `,
          {
            take: this.take,
            skip: this.skip,
            orderBy: [
              {
                createdAt: "desc",
              },
            ],
            where: {
              ...createdAt,
              ...contextCompany,
            },
          }
        );
        if (result.data && result.data.hrSalaryWorkflows) {
          if (result.data.hrSalaryWorkflows.length > 0) {
            if (init === true) {
              this.documents = result.data.hrSalaryWorkflows;
            } else {
              this.documents.push(...result.data.hrSalaryWorkflows);
            }
            this.documentsTotal = result.data.hrSalaryWorkflowsCount;
            this.loading = false;
            this.skip += this.take;
            return { code: "SUCCESS", message: "Load complete" };
          }
          this.documents = [];
          this.documentsTotal = 0;
          this.loading = false;
          return {
            code: "SUCCESS",
            message: "No salary control documents found",
          };
        }
        this.loading = false;
        return { code: "ERROR", message: "Error while loading salaries" };
      } catch (e) {
        this.loading = false;
        return { code: "ERROR", message: `${e.message} (${e.code})` };
      }
    },
    async getDocumentUrls(documentIds) {
      try {
        // use apollo client directly
        const result = await handleGraphQLQuery(
          gql`
            query Document($where: HrSalaryWorkflowDocumentWhereInput!) {
              hrSalaryWorkflowDocuments(where: $where) {
                id
                document {
                  url
                  filename
                  filesize
                }
              }
            }
          `,
          {
            where: {
              id: {
                in: documentIds,
              },
            },
          }
        );
        if (result.data && result.data.hrSalaryWorkflowDocuments) {
          if (result.data.hrSalaryWorkflowDocuments.length > 0) {
            return { code: "SUCCESS", message: null, urls: result.data.hrSalaryWorkflowDocuments };
          } else {
            return {
              code: "SUCCESS",
              message: null,
              urls: [],
            };
          }
        } else {
          this.loading = false;
          return { code: "ERROR", message: "Error while loading salary control document(s)", urls: null };
        }
      } catch (e) {
        this.loading = false;
        return { code: "ERROR", message: e.message + " (" + e.code + ")", urls: null };
      }
    },
    addNewDocuments(files) {
      const companyStore = useCompanyStore();

      let skippedFilesWithNames = [];
      for (let i = 0; i < files.length; i++) {
        let defaultDate = this.defaultDates[1].value;
        let companyDefault = null;
        let isLocked = false;

        // For filenames in auto-format, we can extract the company code and date
        const groups = isFilenameInAutoFormat(files[i].name);
        if (groups) {
          const extractedDate = makeDateFromFileNameDate(groups.date);
          const company = companyStore.companyUsingCode(groups.companycode);

          if (company && company.id && isDateValid(extractedDate)) {
            companyDefault = company;
            defaultDate = extractedDate;
            isLocked = true;
          }
        }

        const newDoc = {
          id: files[i].name + files[i].size + files[i].lastModified,
          data: {
            file: files[i],
            company: companyDefault,
            date: defaultDate,
            feedbackFg: "",
          },
          meta: { locked: isLocked, duplicate: false, duplicateChecked: false, uploaded: false },
        };
        // Only add if file is not already in the list (checking filename, size and lastModified)
        const alreadyAdded = this.newDocuments.find((doc) => doc.id === newDoc.id);
        //console.log("alreadyAdded", alreadyAdded, newDoc.id);

        if (!alreadyAdded) {
          this.newDocuments.push(newDoc);
        } else {
          skippedFilesWithNames.push(files[i].name);
        }
      }
      return skippedFilesWithNames;
    },
    updateDataOnKeyForNewDocumentUsingId(id, data, key) {
      /* CoPilot Refactor
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        doc.data[key] = data;
        doc.meta.duplicateChecked = false;
        doc.meta.duplicate = false;
      }
      */
      const doc = this.findNewDocumentById(id);
      if (doc) {
        doc.data[key] = data;
        doc.meta.duplicateChecked = false;
        doc.meta.duplicate = false;
      }
    },
    getValueForNewDocumentUsingId(id, key) {
      /* CoPilot Refactor
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.data[key];
      } else {
        return null;
      }
      */
      const doc = this.findNewDocumentById(id);
      return doc ? doc.data[key] : null;
    },
    removeNewFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const docIndex = this.newDocuments.findIndex((doc) => doc.id === files[i]);
        if (docIndex >= 0) {
          this.newDocuments.splice(docIndex, 1);
        }
      }
    },
    async uploadNewDocuments_backup_parallel() {
      if (!this.checkIfNewDocumentsAreComplete) return;
      let uploadedIds = [];
      let errors = [];

      // Check for duplicates
      const duplicates = await this.checkNewDocumentsForDuplicates();
      if (duplicates > 0) {
        return { uploadedIds, errors: ["Please remove duplicate entries"] };
      }

      // Upload single file after another
      for (let i = 0; i < this.newDocuments.length; i++) {
        const element = this.newDocuments[i];

        // Skip if one workflow already exists for that period
        if (element.meta.duplicate === true) continue;

        const mutationData = {
          documents: { create: [{ document: { upload: element.data.file }, feedbackFg: element.data.feedbackFg }] },
          company: { connect: { id: element.data.company.id } },
          date: element.data.date,
        };
        try {
          const result = await createSalaryWorkflow({ data: mutationData });
          //console.log(result);
          if (result.data && result.data.createHrSalaryWorkflow && result.data.createHrSalaryWorkflow.id) {
            uploadedIds.push(element.id);
          } else if (result.errors) {
            errors.push(result.errors[0].message);
          }
        } catch (e) {
          console.dir(e);
          errors.push(e.message);
        }
      }
      // Remove files that were successfully uploaded
      //console.log("uploadedIndexes (needed to remove)", uploadedIds);
      this.removeNewFiles(uploadedIds);

      // Update Store with new data
      //this.documents = [];
      await this.getDocuments();

      return { uploadedIds, errors };
    },
    async uploadNewDocuments() {
      if (!this.checkIfNewDocumentsAreComplete) return;
      const uploadedIds = [];
      const errors = [];

      // Upload single file after another
      for (let i = 0; i < this.newDocuments.length; i++) {
        // Check for duplicates
        const duplicates = await this.checkNewDocumentsForDuplicates();
        if (duplicates > 0) {
          break;
          //return { uploadedIds, errors: ["Please remove duplicate entries"] };
        }

        const element = this.newDocuments[i];

        // Skip if one workflow already exists for that period
        if (element.meta.duplicate === true) continue;

        const mutationData = {
          documents: { create: [{ document: { upload: element.data.file }, feedbackFg: element.data.feedbackFg }] },
          company: { connect: { id: element.data.company.id } },
          date: element.data.date,
        };
        try {
          const result = await createSalaryWorkflow({ data: mutationData });
          //console.log(result);
          if (result.data && result.data.createHrSalaryWorkflow && result.data.createHrSalaryWorkflow.id) {
            uploadedIds.push(element.id);
            element.meta.uploaded = true;
          } else if (result.errors) {
            errors.push(result.errors[0].message);
          }
        } catch (e) {
          console.dir(e);
          errors.push(e.message);
        }
      }
      // Remove files that were successfully uploaded
      //console.log("uploadedIndexes (needed to remove)", uploadedIds);
      this.removeNewFiles(uploadedIds);

      // Update Store with new data
      //this.documents = [];
      //await this.getDocuments();

      return { uploadedIds, errors };
    },
    /**
     * Update the status of a document, when a client gives feedback
     */
    async updateDocumentStatus(documentId, status, feedback) {
      let updatedIds = [];
      let errors = [];

      try {
        const result = await updateSalaryControlDocument({ data: { status, feedback }, where: { id: documentId } });
        //console.log(result);
        if (
          result.data &&
          result.data.updateHrSalaryWorkflowDocument &&
          result.data.updateHrSalaryWorkflowDocument.id
        ) {
          updatedIds.push(result.data.updateHrSalaryWorkflowDocument.id);
        } else if (result.errors) {
          errors.push(result.errors[0].message);
        }
      } catch (e) {
        console.dir(e);
        errors.push(e.message);
      }
      return { updatedIds, errors };
    },
    /**
     * Add a document to an existing workflow
     */
    async addDocumentToWorkflow(workflowId, file, feedbackFg) {
      let createdIds = [];
      let errors = [];
      const doc = file ? { document: { upload: file } } : {};

      try {
        const result = await addSalaryControlDocumentToWorkflow({
          data: { ...doc, feedbackFg, workflow: { connect: { id: workflowId } } },
        });
        if (
          result.data &&
          result.data.createHrSalaryWorkflowDocument &&
          result.data.createHrSalaryWorkflowDocument.id
        ) {
          createdIds.push(result.data.createHrSalaryWorkflowDocument.id);
        } else if (result.errors) {
          errors.push(result.errors[0].message);
        }
      } catch (e) {
        console.dir(e);
        errors.push(e.message);
      }
      return { createdIds, errors };
    },
    /**
     * Needed to check for duplicates before uploading
     */
    queryFilterForDuplicatedWorkflows() {
      /* Only allow one workflow per company per period */
      let query = [];
      this.newDocuments.forEach((doc) => {
        //if (!doc.meta.duplicateChecked) {
        if (doc.meta.uploaded === false) {
          // Skip if check did alraedy happen
          query.push({
            company: { id: { equals: doc.data.company.id } },
            date: { equals: doc.data.date },
          });
        }
      });

      return query;
    },
    loadPossibleDuplicatedWorkflows() {
      const query = this.queryFilterForDuplicatedWorkflows();
      console.log("loadPossibleDuplicatedWorkflows", query);
      if (query.length === 0) return;
      return handleGraphQLQuery(
        gql`
          query loadPossibleDuplicatedWorkflows($where: [HrSalaryWorkflowWhereInput!]) {
            hrSalaryWorkflows(where: { OR: $where }) {
              id
              company {
                id
              }
              date
            }
          }
        `,
        { query: query }
      );
    },
    async checkNewDocumentsForDuplicates() {
      let count = 0;
      const result = await this.loadPossibleDuplicatedWorkflows();
      if (result && result.data && result.data.hrSalaryWorkflows) {
        result.data.hrSalaryWorkflows.forEach((workflow) => {
          //console.log(workflow);
          this.newDocuments.forEach((doc) => {
            if (doc.data.company.id === workflow.company.id && doc.data.date === workflow.date) {
              doc.meta.duplicate = true;
              count++;
            }
            //doc.meta.duplicateChecked = true;
          });
        });
      }
      return count;
    },
    resetData() {
      this.documents = [];
      this.newDocuments = [];
      this.loading = false;
    },
  },
});
