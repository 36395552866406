// Core
import { createApp, provide, h } from "vue";
import "./style.css";

import * as Sentry from "@sentry/vue"; // Sentry.io Error Tracking
import { debugIntegration, captureConsoleIntegration } from "@sentry/integrations"; // Sentry.io Error Tracking
import { createPinia } from "pinia"; // Store

// GraphQL
import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "./graphql/client";

// Auth Store
import { useAuthStore } from "./stores/auth";

// Company Store
import { useCompanyStore } from "./stores/company";

// Context Store
import { useUserContextStore } from "./stores/usercontext";

import router from "./router";

// i18n
import i18n from "./i18n";

// Vue Base
import App from "./App.vue";

import Notifications from "notiwind"; // Notifications

// Tootltip
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import SpinnerIcon from "./components/SpinnerIcon.vue";

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

// Make Spinner Icon Global
app.component("SpinnerIcon", SpinnerIcon);

if (import.meta.env.VITE_APP_MODE === "production") {
  Sentry.init({
    app,
    dsn: "https://d51de5adf2ff48cfa8349be8acea506e@o4505385029926912.ingest.sentry.io/4505385032220672",
    environment: import.meta.env.VITE_APP_MODE,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      debugIntegration(),
      captureConsoleIntegration({ levels: ["error", "warn"] }),
    ],
    tracePropagationTargets: ["localhost", "http://localhost:8081", /^https:\/\/hub\.fiscogest\.lu/],
    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.3, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: true,
    attachStacktrace: true,
  });
}

(async () => {
  // Stores must be created before i18n setup because user preferences are stored in the store
  app.use(createPinia());

  // Auth Instance
  const auth = await useAuthStore();
  await useCompanyStore();
  await useUserContextStore();
  await i18n.setupI18n(
    {
      legacy: false,
      fallbackLocale: "en",
      messages: {},
    },
    auth
  );
  //app.use(i18n.vueI18n).use(router).use(Notifications).use(FloatingVue).mount("#app");
  app.use(i18n.vueI18n).use(Notifications).use(FloatingVue);
  app.use(router);

  app.config.errorHandler = (err, vm, info) => {
    console.error("Error:", err);
    console.error("Vue component:", vm);
    console.error("Additional info:", info);
  };

  app.mount("#app");
})();
