import { ref, computed } from "vue";
import { useAuthStore } from "../stores/auth";
import { useUserContextStore } from "../stores/usercontext";
import { useCompanyStore } from "../stores/company";

export function useIsHrEnabled() {
  const isHrEnabled = ref(false);
  const globalSetting = import.meta.env.VITE_FEATURE_HR_ENABLED;
  const userContext = useUserContextStore();
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  const isHrEnabled2 = computed(() => {
    let enabled = false;
    if (globalSetting && globalSetting === "yes") {
      console.log("HR enabled at global level");
      enabled = true;

      // Check for company level access

      //if (userContext && userContext.company.value?.hrEnabled === true) {
      if (companyStore && companyStore.selected.hrEnabled === true) {
        console.log("HR enabled at company level");
        //return true;
        enabled = true;
      } else {
        //console.log("HR disabled at company level or empty", userContext.company.value);
        console.log("HR disabled at company level or empty", companyStore.selected);
        enabled = false;
      }

      // Check for account access
      if (authStore && authStore.account.role.canManageHumanResources === true) {
        console.log("HR enabled at account level");
        //return true;
        enabled = true;
      } else {
        console.log("HR enabled at account level may be empty", authStore.account.role.canManageHumanResources);
      }
    }
    return enabled;
  });
  /*
  if (globalSetting && globalSetting === "yes") {
    console.log("HR enabled at global level");
    isHrEnabled.value = true;

    // Check for company level access
    const userContext = await useUserContextStore();
    if (userContext && userContext.company.value?.hrEnabled === true) {
      console.log("HR enabled at company level");
      //return true;
      isHrEnabled.value = true;
    } else {
      console.log("HR disabled at company level");
      isHrEnabled.value = false;
    }

    // Check for account access
    const authStore = await useAuthStore();
    if (authStore && authStore.account.role.canManageHumanResources === true) {
      console.log("HR enabled at account level");
      //return true;
      isHrEnabled.value = true;
    }
  }

  return isHrEnabled;
  */
  return isHrEnabled2;
}
